
.text-shadow-none{
    text-shadow: 0px 0px 0px;
}


.text-shadow-black{
    text-shadow: 2px 2px 2px black;
}

.text-shadow-grey{
    text-shadow: 1px 1px 2px grey;
}
.text-shadow-white{
    text-shadow: 1px 1px 2px white;
}

.df1{
    font-family: 'Abril Fatface', cursive;
}

.df2{
font-family: 'Newsreader', serif;
/* white-space: nowrap; */
}

.hf1{
font-family: 'Bebas Neue', cursive;
}


.title{
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 1px;
    font-weight: bold;
}

.ff1{
/* font-family: 'Noto Serif Hebrew', serif; */
font-family: 'Alumni Sans', sans-serif;
font-size: large;
line-height: 110%;

/* all weights are available */
}

.text-spacing-1{
    letter-spacing: 1px;

}

.fw100{
    font-weight: 100;
}
.fw200{
    font-weight: 200;
}

.fw300{
    font-weight: 300;
}
.fw400{
    font-weight: 400;
}
.fw500{
    font-weight: 500;
}
.fw600{
    font-weight: 600;
}
.fw700{
    font-weight: 700;
}
.fw800{
    font-weight: 800;
}
.fw900{
    font-weight: 900;
}